/* eslint-disable */
import { ILangState } from '@contextTypes';
import { ILocation, IJobOffer, IPage, IJobPage } from '@standardTypes';

import React from 'react';

import { LangCtx, useContextState } from '@components/contexted';
import { Layout } from '@components/layout';
import { JobOffer } from '@components/organisms/standard';

interface JobProps {
  pageContext: {
    page: any;
    pages: any;
    translationUrls: Record<string, string>;
  };
  location: ILocation;
}

const jobOne: React.FC<JobProps> = ({ pageContext }) => {
  const { page, pages, translationUrls } = pageContext;
  const { language } = useContextState<ILangState>(LangCtx, 'language');
  const { departmentName, image }: IJobOffer = page.ACF_JobOffer;
  const { content, title, ACF_DescriptionTask }: IJobPage = page;

  const jobSlugs = ['job-1', 'job-2', 'job-3', 'job-4'];

  const jobSlugsFiltered = jobSlugs.filter((e) => e !== page.slug).slice(0, 2);
  const jobsSimilar = pages
    .filter((el) => jobSlugsFiltered.includes(el.slug))
    .filter((el) => el.language.code.toLowerCase() === language);
  return (
    <Layout {...page} translationUrls={translationUrls}>
      <JobOffer
        title={title}
        content={content}
        department={departmentName}
        image={image}
        jobsSimilar={jobsSimilar}
        contentTask={ACF_DescriptionTask}
      />
    </Layout>
  );
};

export default jobOne;
